import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";

function RenderToast(props) {

  return (
    <Row>
      <Col xs={6}>
        <Toast
           bg="info"
        >
          <Toast.Header>
            <strong className="me-auto">Thank You!</strong>
          </Toast.Header>
          <Toast.Body>We have received your message!</Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

export default RenderToast;
