import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConnectedDataIcon from "../../../assets/img/connected-data.svg";
import DiversityIcon from "../../../assets/img/diversity.svg";
import DilligenceIcon from "../../../assets/img/dilligence.svg";
import EmissionsIcon from "../../../assets/img/emissions.svg";
import FraudIcon from "../../../assets/img/fraud-analytics.svg";
import InsightIcon from "../../../assets/img/insight-analysis.svg";
import BussinessIcon from "../../../assets/img/bussiness.svg";
import SupplyIcon from "../../../assets/img/supply-chain.svg";
import "./styles.scss";

const KeyFeaturesOpenpill = () => (
  <div className="key-features-wrap">
    <Container>
      <h5>Key Features</h5>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={ConnectedDataIcon} alt="ConnectedDataIcon" />
            <h6>Image-Based Identification</h6>
            <ul className="blue-bulltet-list">
              <li>
                Easily identify pills by taking a photo with your smartphone’s
                camera.
              </li>
              <li>
                Advanced image recognition technology accurately matches the
                pill image with the NLM database.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={DiversityIcon} alt="DiversityIcon" />
            <h6>Comprehensive Drug Information</h6>
            <ul className="blue-bulltet-list">
              <li>
                Access detailed information about the identified pill, including
                its uses, active ingredients, dosage instructions, and potential
                side effects.
              </li>
              <li>
                Information is sourced from the National Library of Medicine,
                ensuring reliability and accuracy.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={DilligenceIcon} alt="DilligenceIcon" />
            <h6>User-Friendly Interface</h6>
            <ul className="blue-bulltet-list">
              <li>
                Intuitive and easy-to-navigate interface designed for users of
                all ages.
              </li>
              <li>
                Quick and straightforward identification process with minimal
                steps.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={BussinessIcon} alt="BussinessIcon" />
            <h6>Secure and Private</h6>
            <ul className="blue-bulltet-list">
              <li>
                User data and images are handled with the utmost security and
                privacy.
              </li>
              <li>
                No personal information is required to use the app, ensuring
                anonymity and peace of mind.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={SupplyIcon} alt="SupplyIcon" />
            <h6>Regular Updates</h6>
            <ul className="blue-bulltet-list">
              <li>
                Frequent updates to the app and database to include the latest
                medications and information.
              </li>
              <li>
                Continuous improvement of image recognition accuracy and user
                experience.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesOpenpill;
