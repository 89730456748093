import React from "react";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import HowClinchHelp from "../../assets/img/how-clinch-can-help.png";
import DigitalProdDev from "../../assets/img/digital-prod-dev.svg";
import BussinessConsulting from "../../assets/img/bussiness-consulting.svg";
import WebAppDev from "../../assets/img/web-app-dev.svg";
import AIDev from "../../assets/img/ai-dev.svg";
import CustomerExp from "../../assets/img/customer-exp.svg";

const HowClinchCanHelp = () => (
  <div className="how-clinch-can-help">
    <Container>
      <h2>How Clinch Can Help</h2>
      <div className="clinch-help-services-wrap">
        <div className="can-help-wrap-left">
          <div className="help-box help-left-box">
            <img src={DigitalProdDev} alt="DigitalProdDev" />
            Digital Product Development
          </div>
          <div className="help-box help-left-box">
            <img src={BussinessConsulting} alt="BussinessConsulting" />
            Business consulting
          </div>
          <div className="help-box help-left-box">
            <img src={WebAppDev} alt="WebAppDev" />
            Web Application Development
          </div>
        </div>
        <div className="how-clinch-help-join">
          <img src={HowClinchHelp} alt="HowClinchHelp image" />
        </div>
        <div className="can-help-wrap-right">
          <div className="help-box help-right-box">
            <img src={AIDev} alt="AIDev" />
            AI Development
          </div>
          <div className="help-box help-right-box">
            <img src={CustomerExp} alt="CustomerExp" />
            Customer Experience
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default HowClinchCanHelp;
