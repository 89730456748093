import React from "react";
import "./styles.scss";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => (
  <div className="privacy-policy">
    <Container>
      <h1>Privacy Policy</h1>
      <p>
        At Clinch Infosystems (P) Ltd, accessible from
        <a href="https://clinchinfosystems.com/">
          https://clinchinfosystems.com/
        </a>
        , one of our main priorities is the privacy of our visitors. This
        Privacy Policy document contains types of information that is collected
        and recorded by Clinch Infosystems (P) Ltd and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <p>
        This Privacy Policy applies only to our online activities and is valid
        for visitors to our website with regards to the information that they
        shared and/or collect in https://clinchinfosystems.com/. This policy is
        not applicable to any information collected offline or via channels
        other than this website.
      </p>
      <h6>Consent</h6>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
      <h6>Information we collect</h6>
      <p>
        The personal information that you are asked to provide, and the reasons
        why you are asked to provide it, will be made clear to you at the point
        we ask you to provide your personal information.
      </p>
      <p>
        We may receive information about you such as your name, email address,
        phone number either online or offline.
      </p>
      <h6>How we use your information</h6>
      <p>
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
      </p>
      <p>This policy is effective as of 1st of November, 2022.</p>
    </Container>
  </div>
);

export default PrivacyPolicy;
