import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConnectedDataIcon from "../../../assets/img/connected-data.svg";
import DiversityIcon from "../../../assets/img/diversity.svg";
import DilligenceIcon from "../../../assets/img/dilligence.svg";
import EmissionsIcon from "../../../assets/img/emissions.svg";
import FraudIcon from "../../../assets/img/fraud-analytics.svg";
import InsightIcon from "../../../assets/img/insight-analysis.svg";
import BussinessIcon from "../../../assets/img/bussiness.svg";
import SupplyIcon from "../../../assets/img/supply-chain.svg";
import "./styles.scss";

const KeyFeaturesMnai = () => (
  <div className="key-features-wrap">
    <Container>
      <h5>Key Features</h5>
      <Row>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={ConnectedDataIcon} alt="ConnectedDataIcon" />
            <h6>Connected Data</h6>
            <p>
              Connecting data to an unmatched level of detail and accuracy – all
              at your fingertips
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={DiversityIcon} alt="DiversityIcon" />
            <h6>Diversity &amp; Inclusion</h6>
            <p>
              Every UK industry and company comprehensively mapped by diversity
              filters
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={DilligenceIcon} alt="DilligenceIcon" />
            <h6>Due Diligence</h6>
            <p>
              Financial due diligence more accurate and comprehensive than ever
              before
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={EmissionsIcon} alt="EmissionsIcon" />
            <h6>Emissions</h6>
            <p>
              Exclusive emissions and analytics data to help drive a greener
              economy
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={FraudIcon} alt="FraudIcon" />
            <h6>Fraud Analytics</h6>
            <p>
              A ‘digital twin’ of the UK private sector to help Government and
              private business fight fraud
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={InsightIcon} alt="InsightIcon" />
            <h6>Insight &amp; Analysis</h6>
            <p>
              Proprietary business intelligence software creates powerful
              insight and analysis
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={BussinessIcon} alt="BussinessIcon" />
            <h6>New Business</h6>
            <p>
              Respond to market changes faster through a data-driven new
              business strategy
            </p>
          </div>
        </Col>
        <Col md={3} xs={12}>
          <div className="feature-box">
            <img src={SupplyIcon} alt="SupplyIcon" />
            <h6>Supply Chain</h6>
            <p>
              Automated supply chain benchmarking for you, your customers and
              your suppliers
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesMnai;
