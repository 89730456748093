import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CodelessAdPlatformIcon from "../../../assets/img/codeless-ad-platforms.svg";
import QuickMultiCampaignIcon from "../../../assets/img/supply-chain.svg";
import AiPoweredAdsIcon from "../../../assets/img/ai-powered-ads.svg";
import "./styles.scss";

const KeyFeaturesMrcmo = () => (
  <div className="key-features-wrap">
    <Container>
      <h5>Key Features</h5>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={CodelessAdPlatformIcon} alt="CodelessAdPlatformIcon" />
            <h6>Codeless Ad Platforms Integration</h6>
            <p>
              Quick no-code setup to take over your digital marketing tasks by
              accessing your data in real time. Official partnerships for
              integration
            </p>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={QuickMultiCampaignIcon} alt="QuickMultiCampaignIcon" />
            <h6>Quick Multichannel Campaign Creation for Performance</h6>
            <p>
              Launch multi-channel campaigns in just a few seconds, optimised
              for performance marketing with “tons” of variations for continuous
              A/B testing. Employ AI for accurate targeting and the creation of
              personalised ad messages.
            </p>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={AiPoweredAdsIcon} alt="AiPoweredAdsIcon" />
            <h6>AI Powered Ads Management 24/7</h6>
            <p>
              Select your business goals and MisterCMO’s AI Autopilot will
              automatically craft and execute 24/7 the algorithms necessary for
              real-time ads and campaigns management or build your own
              automation rules based on the results you want to achieve.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesMrcmo;
