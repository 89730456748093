import React from "react";
import { Container } from "react-bootstrap";
import ScrollTab from "../../components/ScrollTab";
import MoreCaseStudies from "./../../components/MoreCaseStudies/index";
import LetsConnect from "../../components/LetsConnect";

const Services = () => (
  <div className="clinch-services">
    <Container>
      <ScrollTab />
      <MoreCaseStudies />
    </Container>
    <LetsConnect />
  </div>
);

export default Services;
