import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-mnai.png";
import { Container } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewMnai = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        mnAi provides comprehensive data on UK private companies, featuring over
        12 billion data points across 11 million companies and 37 million
        individuals. The platform aids in decision-making, efficiency, and
        complexity reduction through real-time data on emissions, diversity,
        financials, and more. Three years ago, mnAi collaborated with Clinch
        Infosystems to build efficacy and streamline their application's
        scalability, performance, and data accuracy. Clinch executed a
        comprehensive overhaul of mnAi's entire IT ecosystem. The updated
        version of the product, launched in 2022, successfully addressed all the
        identified deficiencies. We are their proud tech partner responsible for
        ongoing development, support and maintenance of the application.
      </p>
      <h5>Value Proposition</h5>
      <ul className="blue-bulltet-list">
        <li>API Access: Custom APIs for real-time data integration.</li>
        <li>Consultancy: End-to-end data-driven solutions.</li>
        <li>Data Services: Augmentation, enrichment, and real-time updates.</li>
        <li>
          Platform: Self-service, cloud-native platform with extensive
          visualizations.
        </li>
      </ul>
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
  </div>
);

export default ProjectOverviewMnai;
