import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-openpill.png";
import { Container } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewOpenpill = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        Open Pill is an innovative mobile application designed to help users
        quickly and accurately identify prescription pills.
      </p>
      <p>
        Leveraging cutting-edge image recognition technology and robust data
        from the National Library of Medicine (NLM) database, Open Pill provides
        users with comprehensive information about their medications. 
      </p>
      <h5>Value Proposition</h5>
      <p>
        Open Pill offers a seamless and efficient solution for identifying
        prescription pills. With a simple snap of a photo, users can access
        detailed information about their medication, including its uses,
        composition, dosage, and potential side effects.
      </p>
      <p>
        By integrating Google’s powerful image recognition API and the extensive
        NLM database, Open Pill ensures accurate and up-to-date information,
        enhancing user safety and peace of mind. This app is ideal for patients,
        caregivers, and healthcare professionals who need a quick and reliable
        way to verify medication details.
      </p>
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
  </div>
);

export default ProjectOverviewOpenpill;
