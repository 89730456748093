import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  const [scrollToTop, setScrollToTop] = useState(false);
  const [scrollToProcess, setScrollToProcess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      setScrollToTop(false); 
    }
  }, [scrollToTop]);

  useEffect(() => {
    if (scrollToProcess) {
      const processElement = document.getElementById("clinchProcess");
      if (processElement) {
        processElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollToProcess(false); 
    }
  }, [scrollToProcess]);

  useEffect(() => {
  }, [location]);

  const handleProcessClick = () => {
    setScrollToProcess(true);
  };

  const handleAboutClick = () => {
    setScrollToTop(true);
  };

  return (
    <footer className="footer">
      <div className="container">
      <Row>
          <Col md={3} xs={12}>
            <img src={logo} alt="logo" />
          </Col>
          <Col md={3} xs={6}>
            <ul className="common-list">
              <li>
                <Link to="/services">Services</Link>
              </li>
              <li>
                <Link to="/projects">Projects</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} xs={6}>
            <ul className="common-list">
              <li>
                <Link to="/" onClick={handleAboutClick}>About</Link>
              </li>
              <li>
                <Link to="/" onClick={handleProcessClick}>Process</Link>
              </li>
            </ul>
          </Col>
          <Col md={3} xs={6}>
            <ul className="common-list">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="socail-footer">
          <span className="copyright-info">
            © 2024 Clinch Infosystems. All rights reserved.
          </span>
          <ul className="common-list social-links ">
            <li>
              <a href="https://x.com/ClinchInfosys" target="_blank" rel="noreferrer">
                <i className="bi bi-twitter-x"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/people/Clinch-Infosystems/100076055993100" target="_blank" rel="noreferrer">
                <i className="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/clinch-infosystems" target="_blank" rel="noreferrer">
                <i className="bi bi-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
