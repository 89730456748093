import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectOverviewGetspot from "./../ProjectOverview/getspot";
import ProjectCaseStudyGetspot from "./../ProjectCaseStudy/getspot";
import KeyFeaturesGetspot from "./../KeyFeatures/getspot";

const ProjectsDetailsGetspot = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyGetspot />
      <ProjectOverviewGetspot />
      <KeyFeaturesGetspot />
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsGetspot;
