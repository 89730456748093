import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectCaseStudyMrcmo from "./../ProjectCaseStudy/mrcmo";
import KeyFeaturesMrcmo from "../KeyFeatures/mrcmo";
import ProjectOverviewMrcmo from "./../ProjectOverview/mrcmo";

const ProjectsDetailsMrcmo = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyMrcmo />
      <ProjectOverviewMrcmo />
      <KeyFeaturesMrcmo />
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsMrcmo;
