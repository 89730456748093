import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectCaseStudyRewardz from "./../ProjectCaseStudy/rewardz";
import ProjectOverviewRewardz from "./../ProjectOverview/rewardz";
import KeyFeaturesRewardz from "./../KeyFeatures/rewardz";
import { Container } from "react-bootstrap";
import "../ProjectOverview/styles.scss";

const ProjectsDetailsRewardz = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyRewardz />
      <ProjectOverviewRewardz />
      <KeyFeaturesRewardz />
      <div className="project-overview ">
        <div className="extra-overview-data">
          <Container>
            <h5>Strategic Outcomes</h5>
            <h6>
              The launch of the website in Q2 2022 yielded several significant
              outcomes
            </h6>
            <ul className="blue-bulltet-list">
              <li>
                <strong>Enhanced User Experience:</strong> The intuitive and
                engaging interface improved user satisfaction and navigation.
                User testing and feedback indicated a substantial reduction in
                bounce rates and increased time spent on the site.
              </li>
              <li>
                <strong>Increased Online Visibility:</strong> The new website
                achieved higher search engine rankings, driving more organic
                traffic. Strategic use of SEO best practices and content
                marketing contributed to this visibility.
              </li>
              <li>
                <strong>Lead Generation and Business Growth:</strong> The site
                effectively captured leads through enhanced forms and calls to
                action, resulting in increase in new business inquiries. The
                transparent and detailed pricing page facilitated conversions
                and client decision-making.
              </li>
              <li>
                <strong>Client Engagement:</strong> The accessible and
                informative design fostered stronger client relationships and
                trust. Positive feedback highlighted the effectiveness of the
                resources page in providing valuable insights and supporting
                client needs.
              </li>
            </ul>
            <h5>Conclusion</h5>
            <p>
              The strategic development and launch of the new website for our
              client, a leading digital incentive program provider, successfully
              met the outlined objectives and delivered measurable business
              benefits. By focusing on user-centric design, modern technology,
              and strategic content, the project not only enhanced the company's
              digital presence but also reinforced its position as an industry
              leader. This case study exemplifies the impact of strategic
              decisions on achieving business goals in the digital era.
            </p>
          </Container>
        </div>
      </div>
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsRewardz;
