import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectCaseStudyMnai from "../ProjectCaseStudy/mnai";
import KeyFeaturesMnai from "../KeyFeatures/mnai";
import ProjectOverviewMnai from "./../ProjectOverview/mnai";

const ProjectsDetailsMnai = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyMnai />
      <ProjectOverviewMnai />
      <KeyFeaturesMnai />
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsMnai;
