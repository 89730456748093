import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DesignProtoIcon from "../../../assets/img/design-prototype.svg";
import DeploymentIcon from "../../../assets/img/deployment.svg";
import LaunchIcon from "../../../assets/img/launch.svg";
import "./styles.scss";

const KeyFeaturesJDO = () => (
  <div className="key-features-wrap">
    <Container>
      <h5>Implementation and Results</h5>
      <p className="sub-heading"> Implementation and Results</p>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={DesignProtoIcon} alt="DesignProtoIcon" />
            <h6>Design and Prototype</h6>
            <ul className="blue-bulltet-list">
              <li>
                Created wireframes and interactive prototypes to visualize the
                new design.
              </li>
              <li>
                Conducted usability testing with real users to refine and
                perfect the user interface.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={DeploymentIcon} alt="DeploymentIcon" />
            <h6>Development</h6>
            <ul className="blue-bulltet-list">
              <li>
                Built the website using modern web technologies, focusing on
                speed, reliability, and scalability.
              </li>
              <li>
                Integrated backend systems to streamline content management and
                updates.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={LaunchIcon} alt="LaunchIcon" />
            <h6>Launch and Optimization</h6>
            <ul className="blue-bulltet-list">
              <li>
                Deployed the redesigned website and conducted thorough testing
                to ensure functionality.
              </li>
              <li>
                Monitored user interaction using analytics tools to gather data
                and make ongoing improvements.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesJDO;
