import React from "react";
import LetsConnect from "../../components/LetsConnect";
import ClinchProcess from "./../../components/ClinchProcess/index";
import ClientAboutClinch from "../../components/ClientAboutClinch";
import HeroBanner from "../../components/HeroBanner";
import ProjectGlance from "../../components/ProjectGlance";
import HowClinchCanHelp from "../../components/HowClinchCanHelp";

const Home = () => (
  <div className="home-page">
    <HeroBanner />
    <HowClinchCanHelp />
    <ProjectGlance />
    <ClinchProcess />
    <ClientAboutClinch />
    <LetsConnect />
  </div>
);

export default Home;
