import Form from "react-bootstrap/Form";

const RenderInput = ({ name, value, onChange, ...props }) => (
  <Form.Control
    type="text"
    placeholder={props.placeholder}
    name={name}
    value={value}
    onChange={onChange}
    {...props}
  />
);

export default RenderInput;
