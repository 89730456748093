import React from "react";
import ProjectBannerImg from "../../../assets/img/jdo-banner.png";
import ProjectJdoLogo from "../../../assets/img/jdo-logo.png";
import "./styles.scss";

const ProjectCaseStudyJdo = () => (
  <div className="project-case-study">
    <div className="case-study-banner">
      <img src={ProjectBannerImg} alt="ProjectBannerImg" />
      <div className="case-study-hover">
        <h2>Case Study</h2>
        <div className="logo-icon">
          <img src={ProjectJdoLogo} alt="ProjectJdoLogo" />
        </div>
      </div>
    </div>
  </div>
);

export default ProjectCaseStudyJdo;
