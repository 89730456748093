import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectCaseStudyJdo from "./../ProjectCaseStudy/jdo";
import ProjectOverviewJDO from "./../ProjectOverview/jdo";
import KeyFeaturesJDO from "./../KeyFeatures/jdo";
import { Container } from "react-bootstrap";

const ProjectsDetailsJDO = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyJdo />
      <ProjectOverviewJDO />
      <KeyFeaturesJDO />
      <div className="project-overview ">
        <div className="extra-overview-data">
          <Container>
            <h5>Strategic Outcomes</h5>

            <ul
              className="blue-bulltet-list"
              style={{ display: "table", marginInline: "auto" }}
            >
              <li>Significant increase in user interaction metrics.</li>
              <li>
                Users reported easier navigation and quicker access to
                information.
              </li>
              <li>
                Mobile visits increased with positive feedback on the improved
                mobile experience.
              </li>
              <li>
                The NGO's message is now clear and compelling, resonating well
                with its audience.
              </li>
            </ul>
            <h5>Conclusion</h5>
            <p>
              Revamping the NGO’s website transformed it into a powerful tool
              for communication and engagement. The new design not only meets
              the organization’s needs but also sets a solid foundation for
              future growth. By focusing on user experience, consistent
              branding, and compelling content, we successfully enhanced the
              NGO’s digital presence and its ability to connect with and impact
              the community.
            </p>
          </Container>
        </div>
      </div>
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsJDO;
