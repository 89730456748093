import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import RealTimeParkingIcon from "../../../assets/img/real-time-parking.svg";
import SpeakerHolderIcon from "../../../assets/img/speaker-holder.svg";
import InteractiveNotifIcon from "../../../assets/img/interactive-notif.svg";
import NationwideCovergaeIcon from "../../../assets/img/nationwide-coverage.svg";
import UserfriendlyInterfaceIcon from "../../../assets/img/user-friendly-interface.svg";
import PrivacySecurityIcon from "../../../assets/img/privacy-security.svg";
import "./styles.scss";

const KeyFeaturesGetspot = () => (
  <div className="key-features-wrap">
    <Container>
      <h5>Key Features</h5>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={RealTimeParkingIcon} alt="RealTimeParkingIcon" />
            <h6>Real-Time Parking Availability</h6>
            <ul className="blue-bulltet-list">
              <li>
                Find free parking spots in real-time based on your desired
                location.
              </li>
              <li>
                View available spots on an interactive map for easy navigation.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={SpeakerHolderIcon} alt="SpeakerHolderIcon" />
            <h6>Seeker and Holder System</h6>
            <ul className="blue-bulltet-list">
              <li>
                Seeker:Search for available parking spots in your desired
                location. View spots that are currently free or will be
                available soon.
              </li>
              <li>
                Holder: Receive notifications when a seeker is looking for your
                spot. Update your estimated time of departure if you plan to
                leave within the next 20 minutes.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={InteractiveNotifIcon} alt="InteractiveNotifIcon" />
            <h6>Interactive Notifications</h6>
            <ul className="blue-bulltet-list">
              <li>
                Seeker receives updates about spots becoming available soon.
              </li>
              <li>
                Holder receives notifications when a nearby seeker is interested
                in their spot, allowing for coordinated departure and arrival.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={NationwideCovergaeIcon} alt="NationwideCovergaeIcon" />
            <h6>Nationwide Coverage</h6>
            <ul className="blue-bulltet-list">
              <li>Extensive database of free parking spots.</li>
              <li>
                Reliable and up-to-date information on parking availability in
                cities, towns, and rural areas.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img
              src={UserfriendlyInterfaceIcon}
              alt="UserfriendlyInterfaceIcon"
            />
            <h6>User-Friendly Interface</h6>
            <ul className="blue-bulltet-list">
              <li>Intuitive design with easy navigation and clear visuals.</li>
              <li>
                Simple search and notification process for both seekers and
                holders.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={PrivacySecurityIcon} alt="PrivacySecurityIcon" />
            <h6>Privacy and Security</h6>
            <ul className="blue-bulltet-list">
              <li>
                Secure handling of user data with strict privacy policies.
              </li>
              <li>
                No personal information is shared with other users, ensuring a
                safe and anonymous experience.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesGetspot;
