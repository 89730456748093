import React from "react";
import "./styles.scss";
import { Container } from "react-bootstrap";

const HeroBanner = () => (
  <div className="hero-banner">
    <Container>
      <h1>
        You present your problem statement, we guarantee to solve that for you.
      </h1>
      <p>
        We are a global digital innovation company that{" "}
        <strong>gurantees delivery</strong> to our customers
      </p>
      <div className="banner-summary">
        <div className="summary-info">
          <span className="counts">500+</span>
          <span className="counts-label">lives touched</span>
        </div>
        <div className="summary-info">
          <span className="counts">27+</span>
          <span className="counts-label">Deliveries</span>
        </div>
        <div className="summary-info">
          <span className="counts">30+</span>
          <span className="counts-label">Team Members</span>
        </div>
      </div>
    </Container>
  </div>
);

export default HeroBanner;
