import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConnectedDataIcon from "../../../assets/img/connected-data.svg";
import HomeIcon from "../../../assets/img/home-icon.svg";
import ServicesIcon from "../../../assets/img/service-icon.svg";
import PricingIcon from "../../../assets/img/pricing-icon.svg";
import ResourceIcon from "../../../assets/img/resources-icon.svg";
import ContactIcon from "../../../assets/img/contact-icon.svg";

import "./styles.scss";

const KeyFeaturesRewardz = () => (
  <div className="key-features-wrap with-bg">
    <Container>
      <h5>Key Features</h5>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={HomeIcon} alt="HomeIcon" />
            <h6>Home Page</h6>
            <ul className="blue-bulltet-list">
              <li>
                Designed to immediately convey the company’s value proposition
                and expertise.
              </li>
              <li>
                Included dynamic content and easy navigation to key areas of the
                site.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={ServicesIcon} alt="ServicesIcon" />
            <h6>Services Page</h6>
            <ul className="blue-bulltet-list">
              <li>
                Detailed descriptions of various incentive programs and
                solutions offered.
              </li>
              <li>
                Included case studies and testimonials to demonstrate success
                and expertise.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={PricingIcon} alt="PricingIcon" />
            <h6>Pricing Page</h6>
            <ul className="blue-bulltet-list">
              <li>
                Transparent and detailed pricing information to help clients
                make informed decisions.
              </li>
              <li>
                Provided options for customizable programs to meet diverse
                client needs.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={ResourceIcon} alt="ResourceIcon" />
            <h6>Resources Page</h6>
            <ul className="blue-bulltet-list">
              <li>
                Comprehensive library of case studies, whitepapers, and industry
                insights.
              </li>
              <li>
                Positioned the company as a thought leader and valuable resource
                in the incentive program industry.
              </li>
            </ul>
          </div>
        </Col>
        <Col md={4} xs={12}>
          <div className="feature-box">
            <img src={ContactIcon} alt="ContactIcon" />
            <h6>Contact Page</h6>
            <ul className="blue-bulltet-list">
              <li>
                Multiple contact options including a form, email, phone number,
                and physical address to facilitate easy communication.
              </li>
              <li>
                Emphasized the company's commitment to client support and
                responsiveness.
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default KeyFeaturesRewardz;
