import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-getspot.png";
import { Container } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewGetspot = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        Get Spot is a revolutionary mobile application designed to simplify the
        search for free parking spaces by connecting users in need of a parking
        spot with those about to vacate one.
      </p>
      <p>
        Whether you are navigating a busy city or a crowded event, Get Spot
        helps you find available parking quickly and efficiently, saving you
        time and reducing stress.
      </p>
      <h5>Value Proposition</h5>
      <p>
        Get Spot transforms the often frustrating task of finding a parking spot
        into a hassle-free experience. By leveraging real-time data and dynamic
        interaction between seekers and holders, the app enhances parking
        efficiency and convenience.
      </p>
      <p>
        With comprehensive coverage of free parking spots, Get Spot offers
        unparalleled ease and reliability for the end user parking needs.
      </p>
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
  </div>
);

export default ProjectOverviewGetspot;
