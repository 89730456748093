import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-rewardz.png";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewRewardz = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        Our client, a premier digital incentive program provider, specializes in
        designing and managing customized rewards and recognition solutions.
        With a vision to extend their market reach and effectively communicate
        their services, the company sought to launch a new website. This
        platform was intended to enhance their digital presence, connect with
        potential clients, and provide comprehensive information about their
        offerings.
      </p>
      <h5>Objectives</h5>
      <p>The strategic objectives for the new website were clear</p>
    </Container>
    <Container>
      <div className="feature-list-overview-wrap">
        <Row>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">1</div>
              <h6>Enhance Usability</h6>
              <p>
                Develop an intuitive, user-friendly interface that showcases the
                company’s services.
              </p>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">2</div>
              <h6>Demonstrate Expertise</h6>
              <p>
                Highlight the company’s proficiency in creating bespoke
                incentive programs.
              </p>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">3</div>
              <h6>Improve Accessibility</h6>
              <p>
                Ensure clients can easily access detailed program and pricing
                information.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12} className="twoCol">
            <div className="feature-box">
              <div className="number-box">4</div>
              <h6>Increase Engagement</h6>
              <p>
                Foster deeper client engagement and build trust in the brand.
              </p>
            </div>
          </Col>
          <Col md={4} xs={12} className="twoCol">
            <div className="feature-box">
              <div className="number-box">5</div>
              <h6>Boost Visibility</h6>
              <p>
                Elevate the company's online presence to generate leads and new
                business opportunities.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <Container className="small">
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
    <Container>
      <div className="feature-list-overview-wrap type2">
        <h5 className="text-center">Development Strategy</h5>

        <div className="steps">
          <div className="step">
            <div className="step-box">
              <div className="step-number-box">1</div>
              <h6>Agile Methodology</h6>
              <ul className="blue-bulltet-list">
                <li>
                  Adopted an agile development approach to facilitate iterative
                  progress and accommodate evolving requirements.
                </li>
                <li>
                  Ensured regular feedback loops and stakeholder involvement
                  throughout the project lifecycle.
                </li>
              </ul>
            </div>
          </div>
          <div className="step">
            <div className="step-box">
              <div className="step-number-box">2</div>
              <h6>Technology Stack</h6>
              <ul className="blue-bulltet-list">
                <li>
                  Utilized cutting-edge web technologies such as HTML, CSS, and
                  JavaScript to build a robust and scalable website.
                </li>
                <li>
                  Implemented responsive design principles to ensure optimal
                  performance across all devices, including desktops, laptops,
                  tablets, and mobile phones.
                </li>
              </ul>
            </div>
          </div>
          <div className="step">
            <div className="step-box">
              <div className="step-number-box">3</div>
              <h6>User-Centric Design</h6>
              <ul className="blue-bulltet-list">
                <li>
                  Prioritized user experience (UX) by conducting user research
                  and usability testing.
                </li>
                <li>
                  Focused on creating a seamless navigation flow and reducing
                  user friction.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default ProjectOverviewRewardz;
