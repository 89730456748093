import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isProcessActive, setIsProcessActive] = useState(false);

  const scrollToSection = (sectionName) => {
    navigate("/", { replace: true });

    setTimeout(() => {
      const element = document.getElementById(sectionName);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  };


  useEffect(() => {
    if (location.pathname === '/services' || location.pathname === '/projects') {
      window.scrollTo(0, 0);
      setIsProcessActive(false);
    }

   
    const processSection = document.getElementById("clinchProcess");
    if (processSection) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting ) {
              setIsProcessActive(true);
            } else {
              setIsProcessActive(false);
            }
          });
        },
        { threshold: 0.5 }
      );
      observer.observe(processSection);

      
      return () => observer.disconnect();
    }
  }, [location]);

  const handleLogoClick = () => {
    navigate("/", { replace: true }); 
    window.scrollTo(0, 0); 
    setIsProcessActive(false);
  };

  return (
    <header
      className={location.pathname === "/" ? "header home-header" : "header"}
      id="header"
    >
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Link to="/" className="navbar-brand" onClick={handleLogoClick}>
            <img src={logo} alt="logo" />
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end flex-grow-1"
          >
            <Nav className="justify-content-end flex-grow-1 pe-3 align-center">
              <Link
                to="/services"
                data-rr-ui-event-key="/services"
                className={`nav-link ${location.pathname === '/services' ? 'active' : ''}`}
              >
                Services
              </Link>
              <Link
                to="/projects"
                data-rr-ui-event-key="/projects"
                className={`nav-link ${location.pathname === '/projects' || location.pathname === '/projects/mrcmo' || location.pathname === '/projects/mnai' || location.pathname === '/projects/openpill' || location.pathname === '/projects/getspot' || location.pathname === '/projects/jdo' || location.pathname === '/projects/rewardz' ? 'active' : ''}`}
              >
                Projects
              </Link>
              <Nav.Link
                onClick={() => scrollToSection("clinchProcess")}
                className={isProcessActive ? 'active' : ''}
              >
                Process
              </Nav.Link>
            </Nav>
            <Button
              variant="outline-primary"
              onClick={() => scrollToSection("letsConnect")}
            >
              Let's Talk
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
