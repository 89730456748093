import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layoout";
import Home from "./pages/Home";
import AllProjects from "./pages/AllProjects";
import ProjectsDetailsMrcmo from "./pages/ProjectDetails/mrcmo";
import ProjectsDetailsMnai from "./pages/ProjectDetails/mnai";
import ProjectsDetailsOpenpill from "./pages/ProjectDetails/openpill/index";
import ProjectsDetailsGetspot from "./pages/ProjectDetails/getspot/index";
import ProjectsDetailsRewardz from "./pages/ProjectDetails/rewardz/index";
import ProjectsDetailsJDO from "./pages/ProjectDetails/jdo/index";
import Services from "./pages/Services";
import NoPage from "./components/NoPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/styles/main.scss";
import PrivacyPolicy from "./pages/PrivacyPolicy/index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<AllProjects />} />
          <Route path="all-projects" element={<AllProjects />} />
          <Route path="projects/mrcmo" element={<ProjectsDetailsMrcmo />} />
          <Route path="projects/mnai" element={<ProjectsDetailsMnai />} />
          <Route
            path="projects/openpill"
            element={<ProjectsDetailsOpenpill />}
          />
          <Route path="projects/getspot" element={<ProjectsDetailsGetspot />} />
          <Route path="projects/jdo" element={<ProjectsDetailsJDO />} />
          <Route path="projects/rewardz" element={<ProjectsDetailsRewardz />} />
          <Route path="services" element={<Services />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
