import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-jdo.png";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewJDO = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        In today's digital age, a compelling online presence is essential for
        non-governmental organizations (NGOs) to achieve their goals. An NGO
        approached us to overhaul their website, which had become outdated, hard
        to navigate, and lacked cohesive branding. This redesign aimed to
        amplify their message, attract more volunteers and donors, and
        strengthen their community impact.
      </p>
      <h5>
        The NGO’s existing website was underperforming in several critical
        areas:
      </h5>
      <ul className="blue-bulltet-list">
        <li>
          <strong>Communication:</strong> The site failed to clearly convey the
          NGO’s mission and goals, leading to confusion among visitors.
        </li>
        <li>
          <strong>User Experience:</strong> Navigational difficulties and slow
          load times frustrated users, decreasing engagement.
        </li>
        <li>
          <strong>Mobile Accessibility:</strong> The website was not optimized
          for mobile devices, resulting in a poor experience for mobile users.
        </li>
        <li>
          <strong>Branding:</strong> The site lacked a consistent visual
          identity, weakening the NGO's brand image.
        </li>
      </ul>
      <h5>Approach</h5>
      <p>
        To address these challenges, we employed a strategic and user-focused
        approach
      </p>
    </Container>
    <Container>
      <div className="feature-list-overview-wrap">
        <Row>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">1</div>
              <h6>Comprehensive Research</h6>
              <ul className="blue-bulltet-list">
                <li>
                  <strong>Understanding the NGO:</strong> We delved into the
                  NGO's mission, vision, and target audience to align the
                  redesign with their core values and goals.
                </li>
                <li>
                  <strong>Competitive Analysis:</strong> Evaluated websites of
                  similar organizations to benchmark industry standards and
                  identify opportunities for differentiation.
                </li>
                <li>
                  <strong>User Feedback:</strong> Conducted surveys and
                  interviews to gather insights on user pain points and
                  expectations.
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">2</div>
              <h6>Redefining Information Architecture</h6>
              <ul className="blue-bulltet-list">
                <li>
                  <strong>Structured Navigation:</strong>
                  Simplified the website’s structure to make it more intuitive,
                  allowing users to find information effortlessly.
                </li>
                <li>
                  <strong>Logical Content Flow:</strong>
                  Organized content into coherent sections with clear headings,
                  ensuring a smooth user journey from the homepage to detailed
                  service descriptions.
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="feature-box">
              <div className="number-box">3</div>
              <h6>Revamping Branding</h6>
              <ul className="blue-bulltet-list">
                <li>
                  <strong>Visual Identity:</strong>
                  Developed a consistent visual theme, including a new color
                  palette, typography, and imagery that resonate with the NGO’s
                  ethos.
                </li>
                <li>
                  <strong>Brand Consistency:</strong>
                  Ensured uniformity across all pages to create a strong,
                  cohesive brand presence.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={12} className="twoCol">
            <div className="feature-box">
              <div className="number-box">4</div>
              <h6>Enhancing User Experience (UX)</h6>
              <ul className="blue-bulltet-list">
                <li>
                  <strong>User-Centric Design:</strong>
                  Focused on creating a design that prioritizes user needs,
                  making navigation straightforward and information easily
                  accessible.
                </li>
                <li>
                  <strong>Mobile Optimization:</strong>
                  Implemented responsive design techniques to provide a seamless
                  experience across all devices, particularly on mobile.
                </li>
                <li>
                  <strong>Performance Improvements:</strong>
                  Optimized website speed by reducing load times through
                  efficient coding practices and image optimization.
                </li>
              </ul>
            </div>
          </Col>
          <Col md={4} xs={12} className="twoCol">
            <div className="feature-box">
              <div className="number-box">5</div>
              <h6>Content Strategy</h6>
              <ul className="blue-bulltet-list">
                <li>
                  <strong>Engaging Content:</strong>
                  Revised content to be clear, concise, and compelling, directly
                  addressing the needs and interests of the target audience.
                </li>
                <li>
                  <strong>Impact Stories:</strong>
                  Highlighted success stories and testimonials to showcase the
                  NGO’s achievements and build trust with visitors. Calls to
                </li>
                <li>
                  <strong>Action:</strong> Strategically placed calls to action
                  throughout the site to encourage donations, volunteer
                  sign-ups, and other forms of engagement.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <Container className="small">
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
  </div>
);

export default ProjectOverviewJDO;
