import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DiscoveyIcon from "../../assets/img/monitor-heart-rate-search.svg";
import DesignIcon from "../../assets/img/paintbrush-painting.svg";
import DevelopIcon from "../../assets/img/code-tags-angle-bracket-monitor.svg";
import DeployIcon from "../../assets/img/shop-rocket-launch-startup.svg";
import MonitorIcon from "../../assets/img/visible-eyeball-open-view.svg";
import "./styles.scss";

const ClinchProcess = () => (
  <div className="clinch-process" id="clinchProcess">
    <Container>
      <h2>Clinch Process</h2>
      <div className="discovery-card-wrap">
        <div className="discovery-card column-4">
          <h3 className="blue">
            <img src={DiscoveyIcon} alt="discovery icon" /> Discovery
          </h3>
          <p className="body-default-copy-medium">
            We consider discovery as the most important stage for any project.
            The primary objective for us in this stage is to align business
            goals and relevant KPIs to measure the success.
          </p>
          <p className="body-default-copy-medium">
            <strong>Result:</strong> User journeys and requirements analysis.
          </p>
        </div>
        <div className="discovery-card column-8">
          <h3 className="blue">
            <img src={DesignIcon} alt="discovery icon" /> Design
          </h3>
          <p className="body-default-copy-medium">
            We believe in the goodness of design. A design approach that makes
            the user’s life easier and where information is available in a
            contextual manner. In this pre-development phase, we transform the
            requirements into detailed design specifications by applying design
            thinking principles.
          </p>
          <p className="body-default-copy-medium">
            <strong>Result:</strong> Intuitive UI/UX with apt underlying
            architecture.
          </p>
        </div>
      </div>
      <div className="discovery-card-wrap">
        <div className="discovery-card">
          <h3 className="blue">
            <img src={DevelopIcon} alt="discovery icon" /> Develop
          </h3>
          <p className="body-default-copy-medium">
            Every business has a different need for technology and we understand
            that. We work with you on leading edge technologies best suited for
            your business through our team of seasoned professionals with proven
            track records have continuously delighted our customers.
          </p>
          <p className="body-default-copy-medium">
            <strong>Result:</strong> MVP and product in testing environment.
          </p>
        </div>
        <div className="discovery-card">
          <h3 className="blue">
            <img src={DeployIcon} alt="discovery icon" /> Deploy
          </h3>
          <p className="body-default-copy-medium">
            We take care of your infrastructure needs. So, whether you want to
            migrate your legacy applications to cloud or want to migrate from
            one cloud platform to another, we take pride in our expertise in
            accomplishing such complex data manoeuvres.
          </p>
          <p className="body-default-copy-medium">
            <strong>Result:</strong> Live product, available to anyone in the
            world, built on layers of security.
          </p>
        </div>
        <div className="discovery-card">
          <h3 className="blue">
            <img src={MonitorIcon} alt="discovery icon" /> Monitoring and
            Control
          </h3>
          <p className="body-default-copy-medium">
            Deploying a solution is just the half battle won the solution needs
            air to breathe life into it and that’s the air that we provide. With
            change management in effect, we assist in the process impact after
            deployment, identify the risks and and also create a mitigation
            plan.
          </p>
          <p className="body-default-copy-medium">
            <strong>Result:</strong> Change management support, increased reliability and availability.
          </p>
        </div>
      </div>
    </Container>
  </div>
);

export default ClinchProcess;
