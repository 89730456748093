import React from "react";
import ProjectOverviewImage from "../../../assets/img/project-overview-mrcmo.png";
import { Container } from "react-bootstrap";
import "./styles.scss";

const ProjectOverviewMrcmo = () => (
  <div className="project-overview">
    <Container className="small">
      <h2>Project Overview</h2>
      <p>
        MisterCMO is an AI-powered performance marketing platform that automates
        the creation, management, and optimization of digital ads. It offers a
        self-service SaaS platform for marketers to enhance their digital
        campaigns effortlessly.
      </p>
      <h6>Clinch is the tech partner of MisterCMO and responsible for:</h6>
      <ul className="blue-bulltet-list">
        <li>
          System Implementation and Integration: Deploying and integrating new
          technology systems seamlessly with existing infrastructure.
        </li>
        <li>
          Technical Support and Maintenance: Providing ongoing technical
          support, troubleshooting, and regular maintenance to ensure system
          reliability and performance.
        </li>
        <li>User Training</li>
        <li>
          Consultation and Strategy: Aligning digital solutions with business
          goals and strategy.
        </li>
      </ul>
      <h5>Value Proposition</h5>
      <ul className="blue-bulltet-list">
        <li>
          Automatic Ad Creation: No-code setup for quick, multi-channel campaign
          launches.
        </li>
        <li>
          AI Optimization: Continuous A/B testing and real-time ads management.
        </li>
        <li>
          Custom Automation: Create bespoke rules for ad management using a
          user-friendly editor.
        </li>
      </ul>
      <div className="project-overview-image">
        <img src={ProjectOverviewImage} alt="ProjectOverviewImage" />
      </div>
    </Container>
  </div>
);

export default ProjectOverviewMrcmo;
