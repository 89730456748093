import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ClientMrcmo from "../../assets/img/client-mrcmo.png";
import ClientMnai from "../../assets/img/client-mnai.png";
import "./styles.scss";

const ClientAboutClinch = () => (
  <div className="client-about-clinch">
    <Container>
      <h2>What Our Clients Say About Clinch</h2>
      <div className="client-card-wrap">
        <div className="client-card">
          <p className="body-default-copy-medium">
            “Clinch is not just a service provider; they are a true partner in
            our growth and success. Their commitment to excellence and
            innovative approach has transformed our IT landscape, making it more
            robust and adaptable to future challenges. If you are looking for an
            IT company that combines technical prowess with exceptional customer
            service, look no further than Clinch.”
          </p>
          <div className="client-info-wrap">
            <img src={ClientMrcmo} alt="client image" />
            <div className="client-info">
              <p className="client-name">Diego Galluzzo</p>
              <p className="client-designation">
                Co-Founder & CEO at MisterCMO
              </p>
            </div>
          </div>
        </div>
        <div className="client-card">
          <p className="body-default-copy-medium">
            “As the CTO of mnAi, I have had the pleasure of working closely with
            Vikas and the team at Clinch Infosystems. They have provided us with
            a dedicated team of Node and React developers who seamlessly
            collaborate with our internal team. Their commitment to delivering
            results has been evident in every project phase.
          </p>
          <p className="body-default-copy-medium">
            I have known Vikas, the Co-Founder of Clinch, for over eight years
            and have worked with Clinch Infosystems for the past three years.
            Vikas’ positive attitude has been instrumental in our digital
            journey.”
          </p>
          <div className="client-info-wrap">
            <img src={ClientMnai} alt="client image" />
            <div className="client-info">
              <p className="client-name">James Prout</p>
              <p className="client-designation">
                CTO, mnAi
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
);

export default ClientAboutClinch;
