import React from "react";
import LetsConnect from "../../../components/LetsConnect";
import ProjectCaseStudyOpenpill from "./../ProjectCaseStudy/openpill";
import ProjectOverviewOpenpill from "./../ProjectOverview/openpill";
import KeyFeaturesOpenpill from "./../KeyFeatures/openpill";

const ProjectsDetailsOpenpill = () => {
  return (
    <div className="project-inner-page">
      <ProjectCaseStudyOpenpill />
      <ProjectOverviewOpenpill />
      <KeyFeaturesOpenpill />
      <LetsConnect />
    </div>
  );
};

export default ProjectsDetailsOpenpill;
