import React from "react";
import LetsConnect from "../../components/LetsConnect";
import AllProjectGlance from "../../components/AllProjectGlance";

const AllProjects = () => (
  <div className="all-project-page">
    <AllProjectGlance />
    <LetsConnect />
  </div>
);

export default AllProjects;
