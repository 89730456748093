import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import ProjectCMO from "../../assets/img/mrcmo-thumb.png";
import ProjectCMOLogo from "../../assets/img/cmo-logo.png";
import ProjectMnai from "../../assets/img/mnai-thumb.png";
import ProjectMnaiLogo from "../../assets/img/mnai-logo.png";
import ProjectOpenpill from "../../assets/img/open-pill-thumb.png";
import TopArrow from "../../assets/img/top-arrow.svg";
import "./styles.scss";
import { Link } from "react-router-dom";

const ProjectGlance = () => (
  <div className="project-glance">
    <Container>
      <h2>Projects at a glance</h2>
      <div className="project-card-wrap">
        <div className="project-card">
            <img src={ProjectCMO} alt="project image" />
          <div className="project-hover">
            <div className="project-hover-btn">
              <img
                src={ProjectCMOLogo}
                alt="project cmo logo"
                className="thumb"
              />
              <Link to="/projects/mrcmo" className="view-case-study-btn">
                <img src={TopArrow} /> View Case Study
              </Link>
            </div>
          </div>
        </div>
        <div className="project-card">
            <img src={ProjectMnai} alt="project image" className="thumb" />
          <div className="project-hover">
            <div className="project-hover-btn">
              <img src={ProjectMnaiLogo} alt="project mnAi logo" />
              <Link to="/projects/mnai" className="view-case-study-btn">
                <img src={TopArrow} /> View Case Study
              </Link>
            </div>
          </div>
        </div>
        <div className="project-card">
          <img src={ProjectOpenpill} alt="project image" className="thumb" />
          <div className="project-hover">
            <div className="project-hover-btn">
              <h4>Open Pill</h4>
              <Link to="/projects/openpill" className="view-case-study-btn">
                <img src={TopArrow} /> View Case Study
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Link to="/all-projects">
          <Button variant="outlined" className="btn-outline-primary">
            View All
          </Button>
        </Link>
      </div>
    </Container>
  </div>
);

export default ProjectGlance;
