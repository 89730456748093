import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import DigitalProdDev from "../../assets/img/digital-development.png";
import BussinessConsulting from "../../assets/img/bussiness-consulting.png";
import WebAppDev from "../../assets/img/web-app-dev.png";
import AiDevServices from "../../assets/img/ai-development-services.png";
import CustomerExp from "../../assets/img/customer-experience.png";
import "./styles.scss";

const ScrollTab = () => {
  // State to track the active tab, default to 1
  const [activeTab, setActiveTab] = useState(1);

  // Intersection observers for each section
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [ref2, inView2] = useInView({ threshold: 0.1 });
  const [ref3, inView3] = useInView({ threshold: 0.1 });
  const [ref4, inView4] = useInView({ threshold: 0.1 });
  const [ref5, inView5] = useInView({ threshold: 0.1 });

  // Update active tab based on visibility
  // React.useEffect(() => {
  //   if (inView) setActiveTab(1);
  //   else if (inView2) setActiveTab(2);
  //   else if (inView3) setActiveTab(3);
  //   else if (inView4) setActiveTab(4);
  //   else if (inView5) setActiveTab(5);
  // }, []);

  // Click handler to set the active tab
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <div className="services-banner">
        <h1>Clinch Services</h1>
      </div>

      <div className="scroll-tab-wrap">
        <div className="navbar">
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={activeTab === 1 ? "tab_item active" : "tab_item"}
            onClick={() => handleTabClick(1)}
          >
            Digital Product Development
          </div>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={activeTab === 2 ? "tab_item active" : "tab_item"}
            onClick={() => handleTabClick(2)}
          >
            Business Consulting
          </div>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={activeTab === 3 ? "tab_item active" : "tab_item"}
            onClick={() => handleTabClick(3)}
          >
            Web Application Development
          </div>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={activeTab === 4 ? "tab_item active" : "tab_item"}
            onClick={() => handleTabClick(4)}
          >
            AI <br />
            Development
          </div>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={activeTab === 5 ? "tab_item active" : "tab_item"}
            onClick={() => handleTabClick(5)}
          >
            Customer Experience
          </div>
        </div>

        <div className="scroll-tab-content">
          {activeTab === 1 && (
            <div ref={ref}>
              <div className="serices-tab-data">
                <div className="left-panel">
                  <h2>Digital Product Development</h2>
                  <p className="body-default-copy-large">
                    For Clinch, digital product development focuses on a user
                    centric approach. Creating new digital products or improving
                    existing ones, our team works closely with you (and your
                    team) to ensure that the product meets the desired user
                    experience and business goals.
                  </p>
                  <p className="body-default-copy-large">
                    We work as your partners committed to giving our best in
                    every way - strategic planning, customer-driven innovation
                    and technical expertise.
                  </p>
                </div>
                <div className="right-panel">
                  <img src={DigitalProdDev} alt="DigitalProdDev" />
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div ref={ref2}>
              <div className="serices-tab-data">
                <div className="left-panel">
                  <h2>Business Consulting</h2>
                  <p className="body-default-copy-large">
                    At Clinch, we provide strategic IT consulting to our
                    clients, be it cutting-edge solutions or IT roadmaps,
                    enabling them to innovate and grow by aligning their digital
                    transformation journey with business goals. We build
                    partnerships with our clients enabling them to remain
                    flexible yet stable in the rapidly changing IT and corporate
                    landscape.
                  </p>
                </div>
                <div className="right-panel">
                  <img src={BussinessConsulting} alt="BussinessConsulting" />
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div ref={ref3}>
              <div className="serices-tab-data">
                <div className="left-panel">
                  <h2>Web Application Development</h2>
                  <p className="body-default-copy-large">
                    Our custom applications are tailored keeping in mind your
                    customer needs, business objectives and technical excellence
                    to disrupt the status quo. Each solution is conceptualized
                    by our team of experts by harnessing the power of innovative
                    techniques, analytics and coding that ensures reliability
                    and scalability. We collaborate with our clients to tailor
                    solutions that enhance returns and give them a distinct
                    competitive edge by using our technology and practical
                    expertise.
                  </p>
                </div>
                <div className="right-panel">
                  <img src={WebAppDev} alt="WebAppDev" />
                </div>
              </div>
            </div>
          )}
          {activeTab === 4 && (
            <div ref={ref4}>
              <div className="serices-tab-data">
                <div className="left-panel">
                  <h2>AI Development Services</h2>
                  <p className="body-default-copy-large">
                    Clinch offers cutting-edge solutions to transform businesses
                    through intelligent automation, data analysis, and advanced
                    machine learning models. These services include developing
                    custom AI models, integrating AI into existing systems, and
                    providing ongoing support and optimization.
                  </p>
                </div>
                <div className="right-panel">
                  <img src={AiDevServices} alt="AiDevServices" />
                </div>
              </div>
            </div>
          )}
          {activeTab === 5 && (
            <div ref={ref5}>
              <div className="serices-tab-data">
                <div className="left-panel">
                  <h2>Customer Experience</h2>
                  <p className="body-default-copy-large">
                    Our Customer Experience offerings are designed to elevate
                    every interaction your customers have with your brand.
                    Through our innovative digital solutions, we optimize user
                    journeys, implement seamless omnichannel strategies, and
                    provide personalized support. By leveraging advanced
                    analytics and AI, we ensure every touchpoint is engaging,
                    efficient, and tailored to meet your customers' needs,
                    driving satisfaction and loyalty.
                  </p>
                </div>
                <div className="right-panel">
                  <img src={CustomerExp} alt="CustomerExp" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ScrollTab;
